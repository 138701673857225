// extracted by mini-css-extract-plugin
export var bgContainer = "index-module--bgContainer--8LgJg";
export var carouselContainer = "index-module--carouselContainer--PBAUL";
export var clearfix = "index-module--clearfix--pQO8v";
export var container = "index-module--container--j36V7";
export var containerGroup = "index-module--containerGroup--Yrdtd";
export var dot = "index-module--dot--OJ9Va";
export var imgContainer = "index-module--imgContainer--xfIdF";
export var itemBox = "index-module--itemBox--uEt5M";
export var itemBoxHover = "index-module--itemBoxHover--FLvzH";
export var itemIntro = "index-module--itemIntro--senyk";
export var itemTitle = "index-module--itemTitle--PhPJo";
export var leftBox = "index-module--leftBox--KwhZj";
export var rightBox = "index-module--rightBox--ue1j2";
export var smallBox = "index-module--smallBox--IX1Yr";
export var smallboxContainer = "index-module--smallboxContainer--BrOop";
export var title = "index-module--title--7CCcL";