//首页 生态组件 渲染

import React, { createRef, useState, useEffect } from "react";

import { Carousel, Row, Col } from "antd";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import * as styles from "./index.module.scss";

import { OverPack } from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";


let timer = undefined;

let i = 0;
const scrolllBox = createRef();

const Ecological = () => {
  const data = useStaticQuery(graphql`
    query {
      ecologicalImg1: file(relativePath: { eq: "ecological1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecologicalImg2: file(relativePath: { eq: "ecological2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecologicalImg3: file(relativePath: { eq: "ecological3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  // console.log(data);
  const renderData = [
    {
      title: "ERP",
      details: [
        "智能化产品库，订单自动处理，高效发货",
        "多维可视化数据分析，掌握实时店铺数据",
        "跨平台产品采集，全流程数据化管理",
      ],

      imgUrl: data.ecologicalImg1.childImageSharp.fluid,
    },
    {
      title: "支付",
      details: [
        "支持PayPal、信用卡等多种方式支付",
        "打通主流收款方式，支持多币种",
        "结算超低汇率、超快到账、稳定安全",
      ],
      imgUrl: data.ecologicalImg2.childImageSharp.fluid,
    },
    {
      title: "运营",
      details: [
        "业内一流运营团队指导",
        "私域流量池沉淀接入",
        "Google Merchant Center，轻松获取天然流量",
      ],
      imgUrl: data.ecologicalImg3.childImageSharp.fluid,
    },
  ];
  let [selectIndex, setSelectIndex] = useState(0);
  const startTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => {
      let res = i % 3;
      // console.log("timer 正在运行");
      scrolllBox.current.style.top = res * -600 + "px";
      setSelectIndex(res);
      i++;
    }, 2000);
  };

  const stopTimer = () => {
    if (timer !== null) {
      clearInterval(timer);
    }
  };

  // 在组件开始渲染的时候加载定时器
  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, []);

  return (
    <div
      className={styles.clearfix}
      style={{ backgroundColor: "#fff", padding: "60px 0" }}
    >
      <div className={styles.container}>
        <div className={styles.title}>生态，人有我优</div>
        <Row>
          {/* pc */}
          <Col xs={0} sm={0} md={24}>

            <div className={styles.leftBox}>
              <div className={styles.containerGroup} ref={scrolllBox}>
                {renderData.map((item, index) => (
                  <div className={styles.imgContainer} key={item.title}>
                    <Img fluid={item.imgUrl} alt="yyds" />
                  </div>
                ))}
              </div>
            </div>
            <OverPack playScale={[0.2]}>
              <QueueAnim className={styles.rightBox} type='left'>
                {renderData.map((item, index) => {
                  return (
                    <div
                      className={[
                        index === selectIndex ? styles.itemBoxHover : "",
                        styles.itemBox,
                      ].join(" ")}
                      onMouseEnter={() => {
                        scrolllBox.current.style.top = -(index * 600) + "px";
                        setSelectIndex(index);
                        i = index;
                        stopTimer();
                      }}
                      onMouseLeave={() => {
                        startTimer();
                      }}
                      key={item.title}
                    >
                      <div className={styles.itemTitle}>
                        <span className={styles.dot} />
                        {item.title}
                      </div>
                      <div className={styles.itemIntro}>
                        {item.details.map((item) => {
                          return <div key={item}>{item}</div>;
                        })}
                      </div>
                    </div>
                  );
                })}
              </QueueAnim>
            </OverPack>
          </Col>
          {/* 移动端展示 */}
          <Col xs={24} sm={24} md={0} className={styles.carouselContainer}>
            <Carousel autoplay>
              {renderData.map((item) => {
                return (
                  <div className={styles.smallboxContainer} key={item.title}>
                    <div className={styles.smallBox}>
                      <Img
                        fluid={item.imgUrl}
                        alt="scrollImg"
                        style={{ width: "80%", margin: "10%" }}
                      />
                      <div className={styles.itemBox}>
                        <div className={styles.itemTitle}>
                          <span className={styles.dot} />
                          {item.title}
                        </div>
                        <div className={styles.itemIntro}>
                          {item.details.map((item) => {
                            return <div key={item}>{item}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Ecological;
