// 首页的icon集合

import React from "react";

import * as styles from "./index.module.scss";

import { graphql, navigate, useStaticQuery } from "gatsby";

import Img from "gatsby-image";

import { Button, Col, Row } from "antd";

import QueueAnim from "rc-queue-anim";
import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const IconsGroup = () => {
  const data = useStaticQuery(graphql`
    query {
      icon1: file(relativePath: { eq: "icons/icon1.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon2: file(relativePath: { eq: "icons/icon2.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon3: file(relativePath: { eq: "icons/icon3.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon4: file(relativePath: { eq: "icons/icon4.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon5: file(relativePath: { eq: "icons/icon5.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon6: file(relativePath: { eq: "icons/icon6.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon7: file(relativePath: { eq: "icons/icon7.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon8: file(relativePath: { eq: "icons/icon8.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const IconGroupData = [
    {
      name: "多语言",
      icon: data.icon1.childImageSharp.fluid,
    },
    {
      name: "多货币",
      icon: data.icon2.childImageSharp.fluid,
    },
    {
      name: "实时物流",
      icon: data.icon3.childImageSharp.fluid,
    },
    {
      name: "安全支付",
      icon: data.icon4.childImageSharp.fluid,
    },
    {
      name: "数据报表",
      icon: data.icon5.childImageSharp.fluid,
    },
    {
      name: "员工权限管理",
      icon: data.icon6.childImageSharp.fluid,
    },
    {
      name: "要素聚合呈现",
      icon: data.icon7.childImageSharp.fluid,
    },
    {
      name: "税费自动结算",
      icon: data.icon8.childImageSharp.fluid,
    },
  ];
  return (
    //页面滚动到0%的时候播放动画
    <OverPack playScale={[0.1]} className={styles.iconGroup}>
      <QueueAnim type="top">
        <div className={styles.title} key="title">
          管理，从未如此得心应手
        </div>
        <TweenOne className={styles.groupContainer} key="group">
          {IconGroupData.map((item, index) => {
            return (
              <TweenOne
                className={styles.iconItem}
                key={index}
                style={{ transform: "scale(0.2)" }}
                animation={{ scale: 1 }}
              >
                <Img fluid={item.icon} className={styles.img} />
                <div className={styles.iconIntro}>{item.name}</div>
              </TweenOne>
            );
          })}
        </TweenOne>
        <Row key="button">
          <Col md={24} xs={0} sm={0}>
            <div
              style={{
                textAlign: "center",
              }}
              className={styles.clearfix}
            >
                <Button
                  shape="round"
                  type="primary"
                  style={{
                    height: "60px",
                    width: "195px",
                    textAlign: "center",
                    margin: "80px auto 0",
                    backgroundColor: "#0075FF",
                    fontSize: "16px",
                  }}
                  onClick={handleRegister}
                >
                  立享30天免费使用
                </Button>
            </div>
          </Col>
        </Row>
      </QueueAnim>
    </OverPack>
  );
};

export default IconsGroup;
