//首页业界一流团队为您服务模块

import React from "react";

import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";


import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';

import * as styles from "./index.module.scss";

const ServiceGroup = () => {
  const data = useStaticQuery(graphql`
    query {
      service1: file(relativePath: { eq: "service1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 284) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service2: file(relativePath: { eq: "service2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 284) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service3: file(relativePath: { eq: "service3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 284) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service4: file(relativePath: { eq: "service4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 284) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service1Icon: file(relativePath: { eq: "service1Icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 48) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service2Icon: file(relativePath: { eq: "service2Icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 48) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service3Icon: file(relativePath: { eq: "service3Icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 48) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service4Icon: file(relativePath: { eq: "service4Icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 48) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const serviceDatas = [
    {
      title: "专业营销团队",
      imgPath: data.service1.childImageSharp.fluid,
      details: ["海外市场营销专家", "助力中国品牌走向世界"],
      iconPath: data.service1Icon.childImageSharp.fluid,
    },
    {
      title: "专业技术团队",
      imgPath: data.service2.childImageSharp.fluid,
      details: ["专业化技术支持", "提供全球化", "安全稳定的系统"],
      iconPath: data.service2Icon.childImageSharp.fluid,
    },
    {
      title: "专业咨询服务",
      imgPath: data.service3.childImageSharp.fluid,
      details: ["小白无忧一对一教学", "助力独立站业务启航"],
      iconPath: data.service3Icon.childImageSharp.fluid,
    },
    {
      title: "专业售后团队",
      imgPath: data.service4.childImageSharp.fluid,
      details: ["7*24小时在线客服", "无论何时何地", "我们都在您身边"],
      iconPath: data.service4Icon.childImageSharp.fluid,
    },
  ];
  return (
    <div className={styles.serviceGroupContainer}>
      <OverPack playScale={[0.2]}>
        <QueueAnim type='bottom'>
          <div className={styles.title} key='title'>业界一流团队为您服务</div>
          <div className={styles.cardGroup} key='group'>
            {serviceDatas.map((item, index) => {
              return (
                <div className={styles.cardContainer} key={index}>
                  <Img
                    fluid={item.imgPath}
                    className={styles.innerImg}
                    alt={item.title}
                  />
                  <div className={styles.slide}>
                    <Img
                      fluid={item.iconPath}
                      className={styles.iconImg}
                      alt={item.title}
                    />
                    <div className={styles.serviceName}>{item.title}</div>
                    <div className={styles.mask}>
                      <div className={styles.center}>
                        {item.details.map((item) => (
                          <div key={item}>{item}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </QueueAnim>
      </OverPack>


    </div>
  );
};

export default ServiceGroup;
