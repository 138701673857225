import React from "react";
import Helmet from "react-helmet";

import { Button, Col, Layout, Row } from "antd";
import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";

import Advantage from "@components/advantage";
import Banner from "@components/banner";
import CardList from "@components/cardList";
import Ecological from "@components/ecological";
import FeatureBox from "@components/featureBox";
import IndexFooter from "@components/footer";
import IndexHeader from "@components/header";
import IconGroup from "@components/iconGroup";
import Partner from "@components/partner";
import Platform from "@components/platform";
import ServiceGroup from "@components/serviceGroup";

import "antd/dist/antd.css";
import * as styles from "./index.module.scss";

const { Content } = Layout;
const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const IndexPage = ({ data }) => {
  const IntroListData = [
    {
      title: "建站，从未如此轻松",
      img: data.image1.childImageSharp.fluid,
      detailList: [
        "卡片式装修，眼见即所得",

        "店铺风格一键切换，常新方能常青",

        "30款精美模板，每一款都匠心独运",
        "只需30分钟，专属独立站即刻拥有",
      ],
    },
    {
      title: "商品上新，从未如此简单",
      img: data.image2.childImageSharp.fluid,
      detailList: [
        "专业一键导品工具，您看上的，我们帮您拿下",

        "独家选品工具，卖什么最赚钱我们最了解",

        "按需打印（POD）不可或缺，我们有最棒的体验和素材",

        "商品全属性批量管理，解放您的时间",
      ],
    },
    {
      title: "营销，从未如此高效",
      img: data.image3.childImageSharp.fluid,
      detailList: [
        "优惠码、满送优惠、自动优惠、组合优惠……比您预期的稍多一点",
        "搜索引擎优化、Google追踪、FB像素……全方位获取自然流量",
        "Google Ads、Twitter Ads、FB Ads……助力引爆社交平台",
        "会员系统、弃单召回、自定义邮件……打造您的专属私域流量池",
      ],
    },
  ];
  return (
    <Layout style={{ background: "#fff" }}>
      {/* index head 里面的信息 */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>首页-Shoprise 官网</title>
      </Helmet>

      {/* header */}
      <IndexHeader />

      {/* banner */}
      <Banner />

      {/* Content */}
      <Content>
        {/* 三个特性介绍模块 */}
        {IntroListData.map((item, index) => {
          const { title, img, detailList } = item;
          return (
            <FeatureBox
              img={img}
              details={detailList}
              title={title}
              key={index}
              reverse={index % 2}
              style={index % 2 ? { backgroundColor: "#F4F9FF" } : {}}
            />
          );
        })}

        {/* Icon集合 */}
        <IconGroup />

        {/* 优势模块 */}
        <Advantage />

        {/* 生态模块 */}
        <Ecological />

        {/* 汇集海外资源组件 */}
        <Platform />

        {/* theme 展示模块 */}
        <CardList />

        {/* 业界一流团队服务模块 */}
        <ServiceGroup />

        {/* 合作伙伴模块 */}
        <Partner />
      </Content>

      {/* footerbanner */}
      <div style={{ position: "relative", textAlign: "center" }}>
        <Row>
          <Col md={24} xs={0} sm={0}>
            <Img fluid={data.footerBg.childImageSharp.fluid} alt="footeImg" />
          </Col>
          <Col md={0} xs={24} sm={24}>
            <Img
              fluid={data.smallFooterBg.childImageSharp.fluid}
              alt="smallFooteImg"
            />
          </Col>
        </Row>

        <div className={styles.buttonContainer}>
          <Row>
            <Col xs={0} sm={0} md={24}>
                <Button
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    height: "60px",
                    lineHeight: "26px",
                    fontSize: "20px",
                  }}
                  type="primary"
                  shape="round"
                  onClick={handleRegister}
                >
                  现在就去
                </Button>
            </Col>
            <Col xs={24} sm={24} md={0}>
                <Button type="primary" shape="round" onClick={handleRegister}>
                  现在就去
                </Button>
            </Col>
          </Row>
        </div>
      </div>

      {/* 公共的footer */}
      <IndexFooter />
    </Layout>
  );
};

export default IndexPage;

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(quality: 100, maxWidth: 720) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "item1.jpg" }) {
      ...squareImage
    }
    image2: file(relativePath: { eq: "item2.png" }) {
      ...squareImage
    }
    image3: file(relativePath: { eq: "item3.jpg" }) {
      ...squareImage
    }
    footerBg: file(relativePath: { eq: "footerBg.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1902) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallFooterBg: file(relativePath: { eq: "smallFooterBg.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
