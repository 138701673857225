import React from "react"

import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";

import * as styles from "./index.module.scss"

export default function Platform() {
    const data = useStaticQuery(graphql`
    query {
      platformBg1: file(relativePath: { eq: "platformBg1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 235) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      platformBg2: file(relativePath: { eq: "platformBg2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 235) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      platformBg3: file(relativePath: { eq: "platformBg3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 235) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      platformBg4: file(relativePath: { eq: "platformBg4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 235) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    const renderData = [
        {
            imgdata: data.platformBg1.childImageSharp.fluid,
            details: ["全球27亿用户"]
        }, {
            imgdata: data.platformBg2.childImageSharp.fluid,
            details: ["每人每天",
                "搜索10次"]
        }, {
            imgdata: data.platformBg3.childImageSharp.fluid,
            details: ["每日浏览",
                "10亿次"]
        }, {
            imgdata: data.platformBg4.childImageSharp.fluid,
            details: ["每月搜索",
                "10亿次"]
        },
    ]

    return <div className={styles.platform}>
        <div className={styles.title}>
            汇聚海外流量
        </div>
        <div className={styles.second_title}>专业顾问，高效开户</div>
        <div className={styles.flexBox}>{
            renderData.map((item, index) => <div className={styles.flexItem} key={index}>
                <Img fluid={item.imgdata} alt="img" />
                <div className={styles.textContainer}>
                    {item.details.map((_item, _index) => <div key={_index}>{_item}</div>)}
                </div>
            </div>)
        }
        </div>
    </div>
}