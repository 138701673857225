//首页banner

import React, { createRef } from "react";

import { Button, Carousel, Col, Row } from "antd";

import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import * as styles from "./index.module.css";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      banner1: file(relativePath: { eq: "banner1.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1902) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner2: file(relativePath: { eq: "banner2.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1902) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner3: file(relativePath: { eq: "banner3.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1902) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner4: file(relativePath: { eq: "banner4.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1902) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  let bannerBox = createRef();
  const handleRegister = (event) => {
    event.preventDefault();
    navigate("/register/");
  };
  return (
    <div className={styles.bigBannerBox}>
      <Row>
        {/* pc端 */}
        <Col md={24} xs={0} sm={0} className={styles.innerBannerbox}>
          <Carousel
            ref={bannerBox}
            autoplay
            pauseOnDotsHover
            pauseOnFocus
            pauseOnHover
          >
            <div className={styles.bannerContainer}>
              <Img
                fluid={data.banner1.childImageSharp.fluid}
                alt="Gatsby Docs are awesome"
              />
              <Button
                type="primary"
                className={styles.button}
                style={{
                  width: "195px",
                  height: "60px",
                  position: "absolute",
                  fontSize: "16px",
                  backgroundColor:"#0075FF"
                }}
                shape="round"
                onClick={handleRegister}
              >
                立享30天免费使用
              </Button>
            </div>
            <div className={styles.bannerContainer}>
              <Img
                fluid={data.banner2.childImageSharp.fluid}
                alt="Gatsby Docs are awesome"
              />
                <Button
                  type="primary"
                  className={styles.button}
                  style={{
                    width: "195px",
                    height: "60px",
                    position: "absolute",
                    fontSize: "16px",
                    backgroundColor:"#0075FF"
                  }}
                  shape="round"
                  onClick={handleRegister}
                >
                  立享30天免费使用
                </Button>
            </div>
          </Carousel>
        </Col>
        {/* 移动端 */}

        <Col md={0} xs={24} sm={24}>
          <Carousel autoplay pauseOnHover>
            <div className={styles.bannerContainer}>
              <Img
                fluid={data.banner3.childImageSharp.fluid}
                alt="Gatsby Docs are awesome"
              />
                <Button
                  type="primary"
                  style={{
                    width: "159px",
                    height: "40px",
                    position: "absolute",
                    bottom: "60%",
                    left: "50%",
                    transform: "translate(-50%)",
                    backgroundColor:"#0075FF"
                  }}
                  shape="round"
                  onClick={handleRegister}
                >
                  立享30天免费使用
                </Button>
            </div>
            <div className={styles.bannerContainer}>
              <Img
                fluid={data.banner4.childImageSharp.fluid}
                alt="Gatsby Docs are awesome"
              />
                <Button
                  type="primary"
                  style={{
                    width: "159px",
                    height: "40px",
                    position: "absolute",
                    bottom: "55%",
                    left: "50%",
                    transform: "translate(-50%)",
                    backgroundColor:"#0075FF"
                  }}
                  shape="round"
                  onClick={handleRegister}
                >
                  立享30天免费使用
                </Button>
            </div>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};
export default Banner;
