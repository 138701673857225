// extracted by mini-css-extract-plugin
export var bgContainer = "index-module--bgContainer--kZRrZ";
export var carouselContainer = "index-module--carouselContainer--1uQHL";
export var clearfix = "index-module--clearfix--AN3+1";
export var container = "index-module--container--dQnYj";
export var containerGroup = "index-module--containerGroup--kFSYu";
export var dot = "index-module--dot--SFJkV";
export var imgContainer = "index-module--imgContainer--zY3nb";
export var itemBox = "index-module--itemBox--nJPdq";
export var itemBoxHover = "index-module--itemBoxHover--dr-jX";
export var itemIntro = "index-module--itemIntro--9pg7W";
export var itemTitle = "index-module--itemTitle--RhZus";
export var leftBox = "index-module--leftBox--C9d8W";
export var rightBox = "index-module--rightBox--+v-OK";
export var smallBox = "index-module--smallBox--Ix81X";
export var smallboxContainer = "index-module--smallboxContainer--2QWaO";
export var title = "index-module--title--PkqzX";