//首页的合作伙伴组件

import React from "react";

import * as styles from "./index.module.scss";

import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import TweenOne from "rc-tween-one"

const Partner = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "partner/img1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "partner/img2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "partner/img3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "partner/img4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img5: file(relativePath: { eq: "partner/img5.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img6: file(relativePath: { eq: "partner/img6.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const renderData = [
    {
      img: data.img1.childImageSharp.fluid,
    },
    {
      img: data.img2.childImageSharp.fluid,
    },
    {
      img: data.img3.childImageSharp.fluid,
    },
    {
      img: data.img4.childImageSharp.fluid,
    },
    {
      img: data.img5.childImageSharp.fluid,
    },
    {
      img: data.img6.childImageSharp.fluid,
    },
  ];
  return (
    <div className={styles.partner}>
      <OverPack playScale={[0.2]}>
        <QueueAnim type='bottom'>
          <div className={styles.title} key='title'>合作伙伴</div>
          <TweenOne className={styles.imgGroup} key='imgGroup'>
            {renderData.map((item, index) => (
              <div className={styles.imgContainer} key={index}>
                <Img fluid={item.img} className={styles.greyImg} />
              </div>
            ))}
          </TweenOne>
          <TweenOne className={styles.seeMore} key='link'>
            <Link to="/partner" style={{ color: "#5A7184" }}>
              查看更多{">>"}
            </Link>
          </TweenOne>
        </QueueAnim>
      </OverPack>

    </div>
  );
};

export default Partner;
