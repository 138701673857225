// extracted by mini-css-extract-plugin
export var cardContainer = "index-module--cardContainer--elMRA";
export var cardGroup = "index-module--cardGroup--UwFVL";
export var center = "index-module--center--GW8AQ";
export var iconImg = "index-module--iconImg--0SIwn";
export var innerImg = "index-module--innerImg--jzzL9";
export var mask = "index-module--mask--bXLDr";
export var serviceGroupContainer = "index-module--serviceGroupContainer--ioKvv";
export var serviceName = "index-module--serviceName--UJFab";
export var slide = "index-module--slide---g6k2";
export var title = "index-module--title--EDQKZ";