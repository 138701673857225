//首页 优势模块

import React, { useState, createRef, useEffect } from "react";

import { Carousel, Row, Col } from "antd";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import * as styles from "./index.module.scss";

import { OverPack } from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";

let timer = null;
let i = 0;

let scrolllBox = createRef();
const Advantage = () => {
  const data = useStaticQuery(graphql`
    query {
      advantageImg1: file(relativePath: { eq: "advantage1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      advantageImg2: file(relativePath: { eq: "advantage2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      advantageImg3: file(relativePath: { eq: "advantage3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const renderData = [
    {
      title: "亚马逊全球云服务器",
      imgUrl: data.advantageImg1.childImageSharp.fluid,
      detail:
        "我们使用全球最快、最稳定的亚马逊全球云服务器集群，拥有最好的速度和性能，自动监控扩容，24小时巡检保障业务稳定",
    },
    {
      title: "智能选品工具",
      imgUrl: data.advantageImg2.childImageSharp.fluid,
      detail:
        "我们拥有市面上最强大的选品工具，最多的搜索维度，最全面的数据结果，让您一搜就知当前最棒的卖品是什么，时刻掌握市场动态，永远快人一步",
    },
    {
      title: "Facebook广告助手",
      imgUrl: data.advantageImg3.childImageSharp.fluid,
      detail:
        "我们拥有市面上最准确的广告归因工具，助力精准评判每条投放渠道带给广告主的线索量和转化率，帮助广告主识别每条渠道的用户特征，便于有针对性地进行业务策略调整",
    },
  ];
  const [selectIndex, setSelectIndex] = useState(0);
  const startTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => {
      let res = i % 3;
      scrolllBox.current.style.top = res * -600 + "px";
      setSelectIndex(res);
      i++;
    }, 2000);
  };
  const stopTimer = () => {
    if (timer !== null) {
      clearInterval(timer);
    }
  };

  // 在组件初次渲染的时候打开定时器

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  }, []);

  return (
    // <div className={styles.clearfix} style={{ backgroundColor: "#fff" }}>
    <div className={styles.container}>
      <div className={styles.title}>优势，人无我有</div>
      <Row>
        {/* pc端展示 */}
        <Col xs={0} sm={0} md={24}>
          <div className={styles.leftBox}>
            <div className={styles.containerGroup} ref={scrolllBox}>
              {renderData.map((item, index) => (
                <div className={styles.imgContainer} key={item.title}>
                  <Img fluid={item.imgUrl} alt="yyds" />
                </div>
              ))}
            </div>
          </div>
          <OverPack playScale={[0.2]}>
            <QueueAnim className={styles.rightBox}>
              {renderData.map((item, index) => {
                return (
                  <div
                    className={[
                      index === selectIndex ? styles.itemBoxHover : "",
                      styles.itemBox,
                    ].join(" ")}
                    onMouseEnter={() => {
                      scrolllBox.current.style.top = -(index * 600) + "px";
                      setSelectIndex(index);
                      i = index;
                      stopTimer();
                    }}
                    onMouseLeave={() => {
                      startTimer();
                    }}
                    key={item.title}
                  >
                    <div className={styles.itemTitle}>
                      <span className={styles.dot} />
                      {item.title}
                    </div>
                    <div className={styles.itemIntro}>{item.detail}</div>
                  </div>
                );
              })}
            </QueueAnim>
          </OverPack>
        </Col>
        {/* 移动端展示 */}
        <Col xs={24} sm={24} md={0} className={styles.carouselContainer}>
          <Carousel autoplay>
            {renderData.map((item) => {
              return (
                <div className={styles.smallboxContainer} key={item.title}>
                  <div className={styles.smallBox}>
                    <Img
                      fluid={item.imgUrl}
                      alt="scrollImg"
                      style={{ width: "80%", margin: "10%" }}
                    />

                    <div className={styles.itemBox}>
                      <div className={styles.itemTitle}>
                        <span className={styles.dot} />
                        {item.title}
                      </div>
                      <div className={styles.itemIntro}>{item.detail}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </div>
    // </div>
  );
};

export default Advantage;
