//首页的theme展示单个组件，以及/themes页面的单个组件

/* 
希望传递进来三个数据：
1. height：包裹img元素的高度
2. width：包裹img元素的宽度
3. imgUrl:图片的地址url(fluid格式的，使用query语句查询到的)
 */
import React, { createRef, useState } from "react";
import * as styles from "./index.module.scss";
import Img from "gatsby-image";


const ImgScrollInBox = ({ width, height, imgUrl, style }) => {
  const imgElement = createRef();
  const [isHover, setIsHover] = useState(false);
  let moveDis = isHover ? -(width / imgUrl.aspectRatio - height) : 0;
  // console.log(width, imgUrl.aspectRatio);
  const hoverFunc = () => {
    setIsHover(true);
  };
  const leaveFunc = () => {
    setIsHover(false);
  };

  return (
    <div
      className={styles.containerBox}
      style={{
        width: width + "px",
        height: height + "px",
        ...style,
      }}
      onMouseOver={hoverFunc}
      onMouseLeave={leaveFunc}
    >
      <Img
        fluid={imgUrl}
        ref={imgElement}
        className={styles.imgContainer}
        style={{ top: moveDis + "px" }}
      />
    </div>
  );
};

export default ImgScrollInBox;
