// extracted by mini-css-extract-plugin
export var button = "index-module--button--t2T1X";
export var detail = "index-module--detail--uXJZ7";
export var dot = "index-module--dot--xKQ36";
export var featureBox = "index-module--featureBox--76gmW";
export var liStyle = "index-module--liStyle--FE6Wr";
export var smallBtn = "index-module--smallBtn--Ru4S9";
export var smallFeature = "index-module--smallFeature--uz+DL";
export var text = "index-module--text--xWZtE";
export var title = "index-module--title--XRfpm";
export var ulElement = "index-module--ulElement--LsLsS";