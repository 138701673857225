// extracted by mini-css-extract-plugin
export var cardGroup = "index-module--cardGroup---g-1S";
export var img1 = "index-module--img1--KDhlF";
export var img2 = "index-module--img2--WnXr7";
export var img3 = "index-module--img3--Idu6U";
export var img4 = "index-module--img4--R8QfV";
export var img5 = "index-module--img5--CklJb";
export var img6 = "index-module--img6--jcMgk";
export var imgContainer = "index-module--imgContainer--YBwKL";
export var smallBox = "index-module--smallBox--Vlx5l";
export var smallGroup = "index-module--smallGroup--9MHW+";
export var smallItem = "index-module--smallItem--n-uwh";