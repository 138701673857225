//首页的三个特性模块的组件
//可以传入 reverse style img title details 几个属性

import { Button, Col, Row } from "antd";
import Img from "gatsby-image";
import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import React from "react";

import { navigate } from "gatsby";
import * as styles from "./index.module.scss";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const FeatureBox = (params) => {
  const { reverse, style, img, title, details } = params;
  // console.log(params, reverse, style);

  //移动相关的参数定义
  const animateMovetoRight = {
    style: {
      opacity: 0,
      transform: "translate(-100px)",
    },
    animation: {
      opacity: 1,
      translateX: "0px",
    },
  };
  const animateMovetoLeft = {
    style: {
      opacity: 0,
      transform: "translate(100px)",
    },
    animation: {
      opacity: 1,
      translateX: "0px",
    },
  };
  return (
    <div style={{ ...style }}>
      <Row>
        {/* pc端显示 */}
        <Col xs={0} sm={0} md={24}>
          <Row className={styles.featureBox} align="middle">
            <Col order={reverse ? 2 : 1} span={12}>
              {/* 动画 */}
              <OverPack playScale={0.1}>
                <TweenOne
                  {...(reverse ? animateMovetoLeft : animateMovetoRight)}
                >
                  <div className={styles.title}>
                    <div className={styles.text}>{title}</div>
                  </div>
                  <ul className={styles.ulElement}>
                    {details.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>

                    <Button
                      type="primary"
                      shape="round"
                      className={styles.button}
                      onClick={handleRegister}
                    >
                      立享30天免费使用
                    </Button>
                </TweenOne>
              </OverPack>
            </Col>
            <Col order={reverse ? 1 : 2} span={12}>
              <OverPack playScale={[0.2]}>
                <TweenOne
                  {...(reverse ? animateMovetoRight : animateMovetoLeft)}
                >
                  <Img fluid={img} />
                </TweenOne>
              </OverPack>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={0}>
          {/* 移动端显示 */}
          <div className={styles.smallFeature}>
            <div className={styles.title}>{title}</div>
            <Img fluid={img} />
            <ul>
              {details.map((item, index) => {
                return (
                  <li key={index} className={styles.liStyle}>
                    <div className={styles.dot} />
                    <div className={styles.detail}>{item}</div>
                  </li>
                );
              })}
            </ul>
              <Button
                type="primary"
                shape="round"
                className={styles.smallBtn}
                onClick={handleRegister}
              >
                立享30天免费使用
              </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default FeatureBox;
