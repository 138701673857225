// 首页theme 展示

import React from "react";
import { Row, Col } from "antd";

import ImgScrollInBox from "@components/imgScrollInBox";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one"

import * as styles from "./index.module.scss";

const CardList = () => {
  //在组件里面使用静态查询
  const data = useStaticQuery(graphql`
    query {
      theme1: file(relativePath: { eq: "theme/theme1.jpg" }) {
        ...themeImage
      }
      theme2: file(relativePath: { eq: "theme/theme6.jpg" }) {
        ...themeImage
      }
      theme3: file(relativePath: { eq: "theme/theme2.jpg" }) {
        ...themeImage
      }
      theme4: file(relativePath: { eq: "theme/theme3.jpg" }) {
        ...themeImage
      }
      theme5: file(relativePath: { eq: "theme/theme4.jpg" }) {
        ...themeImage
      }
      theme6: file(relativePath: { eq: "theme/theme5.jpg" }) {
        ...themeImage
      }
    }
  `);
  const CardImgGroup = [
    data.theme1.childImageSharp.fluid,
    data.theme2.childImageSharp.fluid,
    data.theme3.childImageSharp.fluid,
    data.theme4.childImageSharp.fluid,
    data.theme5.childImageSharp.fluid,
    data.theme6.childImageSharp.fluid,
  ];
  return (
    <Row>
      <Col xs={0} sm={0} md={24}>
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "60px 0", minHeight: "300px" }}
        >
          <OverPack playScale={[0.2]}>
            <TweenOne className={styles.cardGroup} type='bottom' style={{ opacity: "0", transform: "translate(0,10px)", transition: "ease .5s all" }} animation={{ opacity: 1, transform: "translate(0,-10px)" }}>
              {CardImgGroup.map((img, index) => (
                <ImgScrollInBox
                  width={336}
                  height={545}
                  style={{ marginBottom: "103px" }}
                  imgUrl={img}
                  key={index}
                />
              ))}
            </TweenOne>
          </OverPack>

        </div>
      </Col>
      <Col xs={24} sm={24} md={0}>
        <div className={styles.smallBox}>
          <div className={styles.smallGroup}>
            {CardImgGroup.map((img, index) => (
              <div className={styles.smallItem} key={index}>
                <Img fluid={img} alt="phonebg" />
              </div>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CardList;

//注意这里定义了一个查询条件可以在这个项目的所有地方使用，在、pages/theme/index.js 中使用了
export const themeImage = graphql`
  fragment themeImage on File {
    childImageSharp {
      fluid(quality: 80, maxWidth: 336) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
